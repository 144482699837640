<template>
    <div class="com-header">
        <div class="title">
            后台管理系统
        </div>
        <div class="nav">
            <div>
                <el-button :icon="open_btn?'el-icon-s-fold':'el-icon-s-unfold'" size="mini" plain @click="$emit('openOrClose')"/>
            </div>
            <el-dropdown @command="handleCommand" class="menu">
                <div class="menu-title">
                    <el-avatar src="/img/icons/admin5.png" fit="cover"></el-avatar>
                    <span style="padding-left: 10px">
                    {{ account.username || account.account }}
                </span>
                    <i class="el-icon-arrow-down"></i>
                </div>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="modify"><i class="el-icon-edit"></i> 修改密码</el-dropdown-item>
                    <el-dropdown-item command="logout"><i class="el-icon-switch-button"></i> 退出登录</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import {$get, apiUrl} from "@/utils/request";

export default {
    name: "veaHeader",
    props: {
        open_btn: {
            type: Boolean,
            default: () => true
        }
    },
    computed: {
        ...mapState(['account'])
    },
    methods: {
        ...mapMutations(['getAccount']),
        handleCommand(value) {
            switch (value) {
                case "modify":
                    this.$router.replace({name: "modify"})
                    break;
                case "logout":
                    this.$confirm("是否退出登录当前账号？").then(() => {
                        $get(`${apiUrl}admin/logout`, () => {
                            this.getAccount()
                        })
                    })
                    break;
            }
        }
    },
    mounted() {
        this.getAccount()
        // $ajax.post(apiUrl + "admin/checkLogin",{})
    }
}
</script>

<style lang="less" scoped>
.com-header {
    height: 45px;
    background: #0B7D43;
    display: flex;
    align-items: center;
    //justify-content: space-between;
    box-sizing: border-box;

    .title {
        font-size: 20px;
        box-sizing: border-box;
        padding: 10px;
        background: rgba(255, 255, 2255, .6);
        height: 100%;
        color: #ffffff;
        width: 220px;
        letter-spacing: .8rem;
    }

    .nav {
        display: flex;
        justify-content: space-between;
        width: calc(100% - 225px);
        margin-left: 5px;
        align-items: center;

        .menu {
            padding: 10px;
            color: #fff;

            .menu-title {
                display: flex;
                align-items: center;
            }
        }
    }
}
</style>
