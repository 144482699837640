export const category_text = (value) => {
    return value ? value.category_name : "顶级"
}

export const table_state_text = (value) => {
    let text = "有效";
    switch (value) {
        case 2:
        case 0:
            text = "无效"
            break;
    }
    return text;
}

export const table_state_tag_type = (value) => {
    let text = "success";
    switch (value) {
        case 2:
        case 0:
            text = "danger"
            break;
    }
    return text;
}