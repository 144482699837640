<template>
    <div class="com-breadcrumb">
        <el-breadcrumb separator=">">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item v-for="breadcrumb in breadcrumbList" :to="breadcrumb.to">
                {{ breadcrumb.name }}
            </el-breadcrumb-item>
        </el-breadcrumb>
    </div>

</template>

<script>
export default {
    name: "breadcrumb",
    props: {
        breadcrumbList: {
            type: Array,
            default: () => []
        }
    }
}
</script>

<style lang="less" scoped>
.com-breadcrumb {
    margin-top: 10px;
    margin-bottom: 15px;
}
</style>