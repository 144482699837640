<template>
    <el-menu :class="cls" :default-active="activesMenu" :default-openeds="openItems" @select="changeMenu"
             unique-opened :collapse="!isCollapse">
        <menu-list v-for="menuList in menu" :list="menuList"/>
    </el-menu>
</template>

<script>
import menuItems from "@/utils/menu.json"
import MenuList from "@/components/header/menu-list";

export default {
    name: "leftMenu",
    components: {MenuList},
    props: {
        activesMenu: {
            type: String,
            default: () => {
                return "home"
            }
        },
        openItems: {
            type: Array,
            default: () => {
                return ['home']
            }
        },
        isCollapse: {
            type: Boolean,
            default: () => true
        }
    },
    methods: {
        changeMenu(index) {
            this.$router.push(
                {name: index}
            )
        }
    },
    computed: {
        cls() {
            let cls = 'el-menu-vertical'
            if (!this.isCollapse) {
                cls += ' el-menu-vertical-close'
            }

            return cls
        }
    },
    mounted() {

    },
    data() {
        return {
            menu: menuItems.menuItems
        }
    }
}
</script>

<style lang="less" scoped>
.el-menu-vertical {
    max-width: 220px;
    height: calc(100vh - 45px);
}
</style>
