<template>
    <el-menu-item :index="list.index" v-if="!isChildren">
        <i :class="cls"></i>
        <span>{{ list.name }}</span>
    </el-menu-item>
    <el-submenu :index="list.index" v-else>
        <template slot="title">
            <i :class="cls"></i>
            <span>{{ list.name }}</span>
        </template>
        <el-menu-item-group>
            <menu-list v-for="items in list.children" :list="items"/>
        </el-menu-item-group>
    </el-submenu>
</template>

<script>
export default {
    name: "menu-list",
    props: {
        list: {
            default: () => {
            },
            type: Object
        }
    },
    computed: {
        isChildren() {

            return this.list.children.length
        },
        cls() {
            return `vea-font ${this.list.icon} menu-icon`
        }
    },
    mounted() {
        console.log(this.list)
    }
}
</script>

<style lang="less" scoped>
.menu-icon {
    padding-right: 15px;
}

/deep/ .el-menu-item.is-active {
    color: #0B7D43 !important;
    //background: rgba(11, 125, 67, 0.45) !important;
}
</style>
