import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        component: () => import("../views/HomeView"),
        meta: {
            name: "后台管理系统"
        }
    },
    {
        path: "/account/login",
        name: "login",
        component: () => import("../views/LoginView"),
        meta: {
            name: "账号登录"
        }
    },
    {
        path: "/account/activation/:aid",
        name: "account_activation",
        component: () => import("../views/AccountActivationView"),
        meta: {
            name: "管理账号激活"
        }
    },
    {
        path: "/account/modify",
        name: "modify",
        component: () => import("../views/AccountActivationView"),
        meta: {
            name: "修改密码"
        }
    },
    {
        path: "/article/list",
        name: "article",
        component: () => import("../views/article/ListView"),
        meta: {
            name: "文章管理",
            breadcrumb: [{name: "文章列表"}]
        }
    },
    {
        path: "/article/add",
        name: "articleAdd",
        component: () => import("../views/article/EditView"),
        meta: {
            name: "新增文章",
            breadcrumb: [{name: '文章管理', to: {name: 'article'}}, {name: "新增文章"}]
        }
    },
    {
        path: "/article/edit/:id",
        name: "articleEdit",
        component: () => import("../views/article/EditView"),
        meta: {
            name: "编辑文章",
            breadcrumb: [{name: '文章管理', to: {name: 'article'}}, {name: "编辑文章"}]
        }
    },
    {
        path: "/article/examine/:id",
        name: "articleExamine",
        component: () => import("../views/article/ExamineView"),
        meta: {
            name: "审核文章",
            breadcrumb: [{name: '文章管理', to: {name: 'article'}}, {name: "审核文章"}]
        }
    },
    {
        path: "/article/detail/:id",
        name: "articleSee",
        component: () => import("../views/article/ExamineView"),
        meta: {
            name: "查看原文",
            breadcrumb: [{name: '文章管理', to: {name: 'article'}}, {name: "查看原文"}]
        }
    },
    {
        path: "/articleCat/list",
        name: "articleCat",
        component: () => import("../views/articleCat/ListView"),
        meta: {
            name: "文章分类",
            breadcrumb: [{name: "文章分类"}]
        }
    },
    {
        path: "/articleCat/add",
        name: "articleCatAdd",
        component: () => import("../views/articleCat/EditView"),
        meta: {
            name: "新增文章分类",
            breadcrumb: [{name: "文章分类", to: {name: "articleCat"}}, {name: "新增文章分类"}]
        }
    },
    {
        path: "/articleCat/edit/:id",
        name: "articleCatEdit",
        component: () => import("../views/articleCat/EditView"),
        meta: {
            name: "编辑文章分类",
            breadcrumb: [{name: "文章分类", to: {name: "articleCat"}}, {name: "编辑文章分类"}]
        }
    },
    {
        path: "/teacher/list",
        name: "teacher",
        component: () => import("../views/teacher/ListView"),
        meta: {
            name: "讲师管理",
            breadcrumb: [{name: "讲师管理"}]
        }
    },
    {
        path: "/teacher/add",
        name: "teacherAdd",
        component: () => import("../views/teacher/EditView"),
        meta: {
            name: "新增讲师",
            breadcrumb: [{name: "讲师管理", to: {name: "teacher"}}, {name: "新增讲师"}]
        }
    },
    {
        path: "/teacher/edit/:id",
        name: "teacherEdit",
        component: () => import("../views/teacher/EditView"),
        meta: {
            name: "编辑讲师",
            breadcrumb: [{name: "讲师管理", to: {name: "teacher"}}, {name: "编辑讲师"}]
        }
    },
    {
        path: "/teacher/examine/:id",
        name: "teacherExamine",
        component: () => import("../views/teacher/ExamineView"),
        meta: {
            name: "讲师资质审核",
            breadcrumb: [{name: "讲师管理", to: {name: "teacher"}}, {name: "讲师资质审核"}]
        }
    },
    {
        path: "/teacher/details/:id",
        name: "teacherSee",
        component: () => import("../views/teacher/ExamineView"),
        meta: {
            name: "讲师资质",
            breadcrumb: [{name: "讲师管理", to: {name: "teacher"}}, {name: "讲师资质"}]
        }
    },
    {
        path: "/actives/list",
        name: "actives",
        component: () => import("../views/actives/ListView"),
        meta: {
            name: "活动管理",
            breadcrumb: [{name: "活动管理"}]
        }
    },
    {
        path: "/actives/edit",
        name: "activesAdd",
        component: () => import("../views/actives/EditView"),
        meta: {
            name: "新增活动",
            breadcrumb: [{name: "活动管理", to: {name: "actives"}}, {name: "新增活动"}]
        }
    },
    {
        path: "/actives/edit/:id",
        name: "activesEdit",
        component: () => import("../views/actives/EditView"),
        meta: {
            name: "编辑活动",
            breadcrumb: [{name: "活动管理", to: {name: "actives"}}, {name: "编辑活动"}]
        }
    },
    {
        path: "/actives/examine/:id",
        name: "activesExamine",
        component: () => import("../views/actives/DetailView"),
        meta: {
            name: "审核活动",
            breadcrumb: [{name: "活动管理", to: {name: "actives"}}, {name: "审核活动"}]
        }
    },
    {
        path: "/actives/see/:id",
        name: "activesSee",
        component: () => import("../views/actives/DetailView"),
        meta: {
            name: "活动详情",
            breadcrumb: [{name: "活动管理", to: {name: "actives"}}, {name: "活动详情"}]
        }
    },
    {
        path: "/aswe",
        name: "asWe",
        component: () => import("../views/asWe/asWeView"),
        meta: {
            name: "关于我们",
            breadcrumb: [{name: "关于我们"}]
        }
    },
    {
        path: "/cert/import",
        name: "CertImport",
        component: () => import("../views/CertImportView"),
        meta: {
            name: "批量碳中和",
            breadcrumb: [{name: "批量碳中和"}]
        }
    },
    {
        path: "/cert/order",
        name: "certOrder",
        component: () => import("../views/order/CertOrderView"),
        meta: {
            name: "碳中和订单",
            breadcrumb: [{name: "碳中和订单"}]
        }
    },
    {
        path: "/cert/detail/:id",
        name: "certDetail",
        component: () => import("../views/order/CertOrderDetailView"),
        meta: {
            name: "订单详情",
            breadcrumb: [{name: "碳中和订单", to: {name: "certOrder"}}, {name: "订单详情"}]
        }
    },
    {
        path: "/admin",
        name: "adminList",
        component: () => import("../views/admin/AdminListView"),
        meta: {
            name: "管理员",
            breadcrumb: [{name: "管理员"}]
        }
    },
    {
        path: "/admin/add",
        name: "adminAdd",
        component: () => import("../views/admin/AdminEditView"),
        meta: {
            name: "新增管理员",
            breadcrumb: [{name: "管理员", to: {name: "adminList"}}, {name: "新增管理员"}]
        }
    },
    {
        path: "/admin/edit/:id",
        name: "adminEdit",
        component: () => import("../views/admin/AdminEditView"),
        meta: {
            name: "编辑管理员",
            breadcrumb: [{name: "管理员", to: {name: "adminList"}}, {name: "编辑管理员"}]
        }
    },
    {
        path: "/membership",
        name: "membership",
        component: () => import("../views/membership/MemberListView"),
        meta: {
            name: "用户管理",
            breadcrumb: [{name: "用户管理"}]
        }
    },
    {
        path: "/gift",
        name: "gift",
        component: () => import("../views/gift/GiftListView"),
        meta: {
            name: "赠品管理",
            breadcrumb: [{name: "赠品管理"}]
        }
    },
    {
        path: "/gift/add",
        name: "giftAdd",
        component: () => import("../views/gift/GiftEditView"),
        meta: {
            name: "新增赠品",
            breadcrumb: [{name: "赠品管理", to: {name: "gift"}}, {name: "新增赠品"}]
        }
    },
    {
        path: "/gift/edit/:id",
        name: "giftEdit",
        component: () => import("../views/gift/GiftEditView"),
        meta: {
            name: "编辑赠品",
            breadcrumb: [{name: "赠品管理", to: {name: "gift"}}, {name: "编辑赠品"}]
        }
    },
    {
        path: "/score/type",
        name: "scoreType",
        component: () => import("../views/scoreType/typeListView"),
        meta: {
            name: "项目类型",
            breadcrumb: [{name: "项目类型"}]
        }
    },
    {
        path: "/score/type/add",
        name: "scoreTypeAdd",
        component: () => import("../views/scoreType/typeEditView"),
        meta: {
            name: "新增项目",
            breadcrumb: [{name: "项目类型",to:{name:"scoreType"}},{name:"新增项目"}]
        }
    },
    {
        path: "/score/type/add/:id",
        name: "scoreTypeEdit",
        component: () => import("../views/scoreType/typeEditView"),
        meta: {
            name: "编辑项目",
            breadcrumb: [{name: "项目类型",to:{name:"scoreType"}},{name:"编辑项目"}]
        }
    },
    {
        path: "/score/spec",
        name: "scoreSpec",
        component: () => import("../views/spec/specListView"),
        meta: {
            name: "规格管理",
            breadcrumb: [{name: "规格管理"}]
        }
    },
    {
        path: "/score/spec/add",
        name: "scoreSpecAdd",
        component: () => import("../views/spec/specEditView"),
        meta: {
            name: "新增规格",
            breadcrumb: [{name: "规格管理",to:{name:"scoreSpec"}},{name:"新增规格"}]
        }
    },
    {
        path: "/score/spec/edit/:id",
        name: "scoreSpecEdit",
        component: () => import("../views/spec/specEditView"),
        meta: {
            name: "编辑规格",
            breadcrumb: [{name: "规格管理",to:{name:"scoreSpec"}},{name:"编辑规格"}]
        }
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
