import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import VueWechatTitle from "vue-wechat-title";
import * as filters from "./utils/filters";
import SlideVerify from "vue-monoplasty-slide-verify";
import "@/assets/webs/iconfont.css"

Vue.use(ElementUI);
Vue.use(VueWechatTitle);
Vue.use(SlideVerify);

Object.keys(filters).forEach(k => Vue.filter(k, filters[k]))

Vue.config.productionTip = false;

window.app = new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
