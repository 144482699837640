import axios from "axios";

axios.defaults.withCredentials = true;
export const $ajax = axios.create({
    headers: {
        'Content-Type': 'application/json,charset=UTF-8'
    },
    withCredentials: true
})

export const apiUrl = process.env.VUE_APP_API_URL
export const serveUrl = process.env.VUE_APP_SERVE_HOST

$ajax.interceptors.response.use((response) => response, (error) => {
    const loginItems = window.sessionStorage.getItem("isLogin");
    if (!loginItems) {
        let message = {
            type: "error",
            message: "系统错误，请稍候再试",
            duration: 1000
        };
        if (error.response && error.response.data) {
            message.message = error.response.data.errMsg || "系统错误，请稍候再试"

            if (error.response.data.errCode === 401) {
                window.sessionStorage.setItem("isLogin", "1")
                message.onClose = () => {
                    // window.localStorage.removeItem("user_accounts");
                    // window.localStorage.removeItem("DATA_CONFIG");
                    window.sessionStorage.clear()
                    window.app.$router.replace({name: "login"});
                }
            }
        }

        window.app.$message(message)
    }

    return Promise.reject(error)
})

export const $get = (url, callback, fail) => {
    $ajax.get(url).then(({data}) => {
        if (callback) {
            callback(data)
        }
    }).catch(() => {
        if (fail) {
            fail();
        }
    })
}

export const $post = (url, params, callback, fail) => {
    $ajax.post(url, params).then(({data}) => {
        if (callback) {
            callback(data)
        }
    }).catch(() => {
        if (fail) {
            fail()
        }
    })
}
