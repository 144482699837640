<template>
    <div id="app">
        <!--   登录状态下的代码     -->
        <div v-if="isLogin">
            <vea-header :open_btn="menuOpenOrClose" @openOrClose="openOrClose"/>
            <left-menu :actives-menu="$route.name" :is-collapse="menuOpenOrClose"/>
            <div :class="content_cls">
                <breadcrumb :breadcrumb-list="$route.meta.breadcrumb" v-if="isIndex"/>
                <router-view v-wechat-title="$route.meta.name"/>
            </div>
        </div>
        <!--   未登录状态|修改密码下的代码     -->
        <router-view v-wechat-title="$route.meta.name" v-else/>
    </div>
</template>

<style lang="less">
@import "assets/veafonts/iconfont.css";
//@import "assets/veafonts/verfont.css";


.vea-font-small {
    font-size: 1.35rem;
}

.vea-font-mini {
    font-size: 0.825rem;
}

.vea-font-2x {
    font-size: 1.75rem;
}


.vea-font-max {
    font-size: 2.75rem;
}

* {
    margin: 0;
    padding: 0;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.content {
    position: fixed;
    padding: 10px;
    box-sizing: border-box;
    top: 50px;
    left: 225px;
    bottom: 5px;
    right: 0;
    overflow-y: auto;
    //background: dodgerblue;

    &.content-left {
        left: 70px !important;
    }
}

*[class^=pages-] {
    margin-top: 10px;

    .search {
        margin: 10px auto;
    }

    .data {
        margin: 10px auto;
    }

    .vea-form {
        .el-input {
            width: auto;
        }

        .el-textarea {
            width: auto;

            textarea {
                resize: none;
                height: 100px;
            }
        }

        .vea-aux {
            font-size: 12px;
            color: #cccccc;
        }
    }
}

.el-table__header {
    text-align: center;

    tr {

    }

    td, th {
        text-align: center !important;
        border-top: 5px solid #0B7D43;
    }

    .cell {
        text-align: center;
    }
}

.vea-option-btn {
    display: flex;
    flex-wrap: wrap;
    //align-items: center;
    .el-button + .el-button {
        margin-left: 0 !important;
    }

    .el-button {
        padding: 0 10px;
        margin-bottom: 5px;
    }
}

.vea-desc {
    margin: 10px auto;

    .vea-desc-title {
        border-left: 5px solid #0B7D43;
        padding-left: 5px;
        font-weight: 600;
    }

    .el-descriptions-row {
        td {
            width: 20% !important;
        }

        th {
            background: rgba(11, 125, 67, 0.45);
            color: #fff;
        }
    }
}
</style>
<script>
import VeaHeader from "@/components/header/vea-header";
import LeftMenu from "@/components/header/leftMenu";
import Breadcrumb from "@/components/header/breadcrumb";

export default {
    components: {Breadcrumb, LeftMenu, VeaHeader},
    computed: {
        isLogin() {
            return this.$route.name !== 'login' && this.$route.name !== 'modify' && this.$route.name !== 'account_activation'
        },
        isIndex() {
            return this.$route.name !== "home"
        },
        content_cls() {
            let cls = 'content'
            if (!this.menuOpenOrClose) {
                cls += ' content-left'
            }
            return cls
        }
    },
    data() {
        return {
            menuOpenOrClose: true
        }
    },
    methods: {
        openOrClose() {
            this.menuOpenOrClose = !this.menuOpenOrClose
        }
    }
}
</script>
