import Vue from "vue";
import Vuex from "vuex";
import {$get, $post, apiUrl} from "@/utils/request";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        account: {}
    },
    getters: {},
    mutations: {
        getAccount(state, playLoad) {
            $get(apiUrl + "admin/checkLogin", (res) => {
                this.state.account = res.result
            })
        }
    },
    actions: {},
    modules: {},
});
